<template>
  <!-- 库存管理 -->
  <div class="inventoryManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr">
      <template>
        <el-form-item
          label="商品品名"
          prop="warehouseName"
        >
          <!-- <el-select
            v-model="formInline.goodsId"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="warehouseNameremoteMethod"
          >
            <el-option
              v-for="item in warehouseNameArr"
              :key="item.goodsId"
              :label="item.warehouseName"
              :value="item.goodsId"
            />
          </el-select> -->
          <el-cascader
            ref="goodsCategoryRef"
            v-model="formInline.categoryArr"
            placeholder="请选择"
            :options="categoryOption"
            :props="categoryCascadeProps"
            clearable
            @change="categoryChange"
          />
        </el-form-item>
        <el-form-item
          label="仓库名称"
          prop="warehouseName"
        >
          <el-select
            v-model="formInline.warehouseName"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="warehouseNameremoteMethod"
          >
            <el-option
              v-for="item in warehouseNameArr"
              :key="item.warehouseName"
              :label="item.warehouseName"
              :value="item.warehouseName"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="skip(2)">
          移库
        </el-button>
        <el-button size="small" type="primary" @click="skip(3)">
          过户
        </el-button>
        <el-button size="small" type="primary" @click="skip(4)">
          盘点
        </el-button>
      </div>
      <Table :item-data="itemData" :selection="true" :list-data="listData" :loading="loading" @getCheckData="getCheckData">
        <template #invWeight="{row}">
          <span>{{ row.invWeight }}{{ row.unit||'吨' }}</span>
        </template>
        <template #avlbWeight="{row}">
          <span>{{ row.avlbWeight }}{{ row.unit||'吨' }}</span>
        </template>
        <template slot="dynamicInsert">
          <el-table-column type="expand">
            <template slot-scope="props">
              <table class="tableBox">
                <tr>
                  <td>库区编号</td>
                  <td>库区描述</td>
                  <td>货位号</td>
                  <td>货位描述</td>
                  <td>库存数量/单位</td>
                </tr>
                <tr v-for="(item, index) in props.row.wmsInventorydetailRespList" :key="index">
                  <td>{{ item.goodsYardNo }}</td>
                  <td>{{ item.goodsYardRemark }}</td>
                  <td>{{ item.detailLocationNo }}</td>
                  <td>{{ item.detailLocationDesc }}</td>
                  <td>{{ item.detailInvWeight }}{{ item.unit||'吨' }}</td>
                </tr>
                <tr v-if="props.row.wmsInventorydetailRespList.length===0">
                  <td colspan="8">
                    暂无数据！
                  </td>
                </tr>
              </table>
            </template>
          </el-table-column>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import { inventoryPage, warehouseList } from '../../api/warehousingSystem'
import { goodsGetGoodsCategoryList } from '@/api/shoppingMall'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      selectTableList: [],
      itemData: [
        { label: '库存编号', prop: 'inventoryNo', width: 180, path: '/warehousingSystem/inventoryDetails', pathParameter: 'id' },
        { label: '商品品名', prop: 'goodsNameArr', width: 180 },
        { label: '材质/规格/产地/件重', prop: 'goodsAttribute', width: 300 },
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '货主名称', prop: 'custName', width: 180 },
        { label: '下游客户名称', prop: 'downCustName', width: 180 },
        { label: '库存数量/单位', prop: 'invWeight', width: 100 },
        { label: '可用数量/单位', prop: 'avlbWeight', width: 100 },
        { label: '更新时间', prop: 'modifyTime', width: 180 }
      ],
      formItemArr: [
        { type: 'input', label: '货主名称', value: 'custName' },
        { type: 'input', label: '下游客户名称', value: 'downCustName' }
      ],
      loading: false,
      total: 0,
      listData: [],

      formInline: {
        pageSize: 10,
        pageNum: 1
        // categoryArr: []
      },
      warehouseNameArr: [],
      // 划级联渲染规则
      categoryCascadeProps: {
        value: 'code',
        label: 'goodsName'
      },
      categoryOption: []
    }
  },
  mounted() {
    this.getCategoryList()
    this.warehouseNameremoteMethod()
  },
  methods: {
    // 点击展开的时候
    // getExpandChangeRow(value){
    //   inventoryDetail({ id: value.id }, res => {
    //     this.listData.forEach(item=>{
    //       if(item.id===value.id){
    //         this.$nextTick(()=>{
    //           this.$set(item,'wmsInventorydetailRespList',[...res.data.wmsInventorydetailRespList||[]])
    //         })
    //       }
    //     })
    //     this.$forceUpdate()
    //   })
    // },
    warehousingDetail(row) {
      // 入库明细跳转
      this.$router.push({ path: '/warehousingSystem/stockManagement', query: { inventoryNo: row.inventoryNo }})
    },
    outboundDetails(row) {
      // 出库明细跳转
      this.$router.push({ path: '/warehousingSystem/warehouseManagement', query: { inventoryNo: row.inventoryNo }})
    },
    skip(type) {
      if (!this.selectTableList || this.selectTableList.length === 0) return this.$message.warning('请选择库存数据！')
      // 判断已选数组是否是同一仓库 同一货主
      const warehouseNameArr = this.selectTableList.map(item => item['warehouseName'])
      const warehouseNameSet = new Set(warehouseNameArr)
      const custNameArr = this.selectTableList.map(item => item['custName'])
      const custNameSet = new Set(custNameArr)
      // 有不同的仓库/货主 提示用户重新选择
      if (warehouseNameSet.size !== 1 || custNameSet.size !== 1) return this.$message.warning('请选择同一仓库和同一货主的库存数据！')
      switch (type) {
        case 1:
          this.$router.push({ path: '/warehousingSystem/warehouseStorage', query: { id: this.editFormData.id }})
          break
        case 2:
          {
            const wmsrespList = []
            this.selectTableList.map((item) => {
              item.wmsInventorydetailRespList.map((cl) => {
                cl.outerId = item.id
                wmsrespList.push(cl)
              })
            })
            const detailsObj = {
              warehouseId: this.selectTableList[0].warehouseId,
              custId: this.selectTableList[0].custId,
              custName: this.selectTableList[0].custName,
              warehouseName: this.selectTableList[0].warehouseName,
              wmsInventorydetailRespList: wmsrespList
            }
            this.$store.commit('setResourceByTaskObj', { ...detailsObj })
            this.$router.push({ path: '/warehousingSystem/addShiftingParking', query: { type }})
          }
          break
        case 3:
          {
            const detailsObj = {
              custName: this.selectTableList[0].custName,
              custId: this.selectTableList[0].custId,
              warehouseId: this.selectTableList[0].warehouseId,
              warehouseName: this.selectTableList[0].warehouseName,
              wmsInventorydetailRespList: [...this.selectTableList]
            }
            this.$store.commit('setResourceByTaskObj', { ...detailsObj })
            this.$router.push({ path: '/warehousingSystem/addTransferOwnership', query: { type }})
          }
          break
        case 4:
          {
            const wmsrespList = []
            this.selectTableList.map((item) => {
              item.wmsInventorydetailRespList.map((cl) => {
                wmsrespList.push(cl)
              })
            })
            const detailsObj = {
              custName: this.selectTableList[0].custName,
              custId: this.selectTableList[0].custId,
              warehouseId: this.selectTableList[0].warehouseId,
              warehouseName: this.selectTableList[0].warehouseName,
              wmsInventorydetailRespList: wmsrespList
            }
            this.$store.commit('setResourceByTaskObj', { ...detailsObj })
            this.$router.push({ path: '/warehousingSystem/transferOwnershipAdd', query: { type }})
          }
          break
        default:
          break
      }
    },
    getCheckData(value) {
      this.selectTableList = [...value]
      // this.editFormData = { ...value }
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inventoryPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.map((item) => {
          item.wmsInventorydetailRespList.map(jtem => {
            item.detailProductions = jtem.detailProductions
            item.detailWeights = jtem.detailWeights
            item.categoryName = jtem.categoryName
            item.varietyName = jtem.varietyName
          })
          item.goodsAttribute = (item.materials ? item.materials : '-') + '/' + (item.specs ? item.specs : '-') + '/' + (item.detailProductions ? item.detailProductions : '-') + '/' + (item.weights ? item.weights : '-')
          item.goodsNameArr = (item.categoryName || '') + (item.categoryName ? '/' : '') + (item.varietyName || '') + (item.varietyName ? '/' : '') + item.goodsName
          return item
        })
        this.total = res.data.total
      })
    },
    // 联动搜索仓库列表
    warehouseNameremoteMethod(value) {
      warehouseList(value || '', res => {
        this.warehouseNameArr = res.data
      })
    },
    // 品类 级联选择器数据
    getCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.categoryOption = res.data
        this.changeCategoryList(this.categoryOption)
      })
    },
    // 递归删除空子级
    changeCategoryList(data) {
      data.map((item) => {
        this.changeCategoryList(item.children)
        if (item.children.length === 0) {
          delete item.children
        }
      })
    },
    // 级联change事件
    categoryChange(val) {
      if (!val || val.length === 0) return
      this.formInline.goodsId = val[2]
    }
  }
}
</script>

<style lang="scss" scoped>
.inventoryManagement {
  .tableBox {
    margin-left: 92px;
    text-align: center;
    width: 1200px;
    border-collapse: collapse;

    td {
      width: 150px;
      height: 40px;
      border: 1px solid #ededed;
    }
  }

  .childrenTable {
    margin-left: 100px;
  }

  .buttonCombination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
